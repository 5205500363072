<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <disorder-template
      :heading="$t('chewing-and-spitting')"
      :next="next"
      :previous="previous"
      :sources="sources"
    >
      <p
        v-for="paragraph in $t('disorders-chewing-and-spitting')"
        :key="paragraph"
      >
        {{ paragraph }}
      </p>
    </disorder-template>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DisorderTemplate from "@/components/DisorderTemplate.vue";

export default {
  name: "chewing-and-spitting",
  components: {
    DisorderTemplate,
    Breadcrumbs
  },
  data: () => ({
    next: {
      slug: "relatives",
      url: "/disorders/relatives"
    },
    previous: {
      slug: "pica-syndrom",
      url: "/disorders/pica-syndrom"
    },
    sources: [
      "[1] Aouad P, Hay P, Soh N, Touyz S. Chew and Spit (CHSP): a systematic review. J Eat Disord. 22. August 2016;4(1):23."
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("disorders"),
          disabled: false,
          href: "/disorders"
        },
        {
          text: this.$t("chewing-and-spitting"),
          disabled: true
        }
      ];
    }
  }
};
</script>
